import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useEffect, useState} from "react";
import PhoneNumberInput from "./PhoneNumberInput ";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal({callback,statval}) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(statval);

    }, [statval]);
    const handleOpen = () => {
        setOpen(true);
        callback(true)
    }
    const handleClose = () =>{
        setOpen(false);
        callback(false)
    }
    return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <PhoneNumberInput statvalcalback={function (){
                        setOpen(false)
                        callback(false)
                    }} />
                </Box>
            </Modal>
        </div>
    );
}
