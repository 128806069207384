import React, { useRef, useState } from 'react';
import {Alert, AlertTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";

const PhoneNumberInput = ({statvalcalback}) => {
    const [errorPhone, setErrorPhone] = useState('');
    const [errorName, setErrorName] = useState('');
    const [msg, setMsg] = useState("");

    let NumberPhoneRef = useRef(null);
    let FullNameRef = useRef(null);

    const handlePhoneNumberChange = (event) => {
        console.log(NumberPhoneRef.current);
    };

    const handlePhoneNumberSubmit = () => {
        const input = NumberPhoneRef.current.value;
        const fullname = FullNameRef.current.value;
        const armenianPhoneNumberRegex = /^(?:\+\d+)?[\d\s-]+$/;
        var fullNameRegex = /^([A-Za-z]+|[Ա-Ֆա-ֆ]+)(?: ([A-Za-z]+|[Ա-Ֆա-ֆ]+))?$/;

        const isValid = armenianPhoneNumberRegex.test(input);
        const isValidName = fullNameRegex.test(fullname);

        if (!isValid) {
            setErrorPhone('Սխալ: Հեռախոսահամարը պետք է պարունակի միայն թվեր, առանց նշանների, ու առանց բացատների:');
        } else {
            setErrorPhone('');
        }

        if (!isValidName) {
            setErrorName('Սխալ: Անունը և ազգանունը պետք է պարունակեն միայն տառեր:');
        } else {
            setErrorName('');
        }
        if(isValid && isValidName){
            setMsg('Submitted phone number')
            NumberPhoneRef.current.value=''
            FullNameRef.current.value=''
            console.log('Submitted phone number:', input, fullname);
           setTimeout(()=>{
               statvalcalback()
           },1000)
        }else{
            setMsg('')
        }


    };

    return (
        <div className={"orderCall"}>


            {
                msg&&(
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        This is a success Alert with an encouraging title.
                    </Alert>
                )
            }
            <h3 className={'orderCallMsg'}>ՊԱՏՎԻՐԵԼ ԶԱՆԳ</h3>
            <TextField
                label="Անուն Ազգանուն"
                variant="outlined"
                inputRef={FullNameRef}
                error={!!errorName} // Show error when input is not valid
                helperText={errorName}
                inputProps={{
                    maxLength: 17,
                }}
            />
            <TextField
                label="Հեռախոսահամար"
                variant="outlined"
                inputRef={NumberPhoneRef}
                error={!!errorPhone}
                helperText={errorPhone}
                inputProps={{
                    maxLength: 13,
                }}
            />
            <Button variant="contained" className={"net-btn"} color="primary" onClick={handlePhoneNumberSubmit}>
                Այժմ ուղարկել
            </Button>
        </div>
    );
};

export default PhoneNumberInput;
