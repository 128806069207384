import React from 'react';

function NewProduct({title = "product", data}) {
    const siteUrl = process.env.REACT_APP_SITE_URL;

    return (
        <div className="product-showcase">

            <h3 className="showcase-heading">{title}</h3>

            <div className="showcase-wrapper">

                <div className="showcase-container">
                    {
                        data && data?.map((res) => (
                    <div className="showcase" key={res.id}>

                        <a href="#" className="showcase-img-box">
                            <img src={siteUrl+ res.item_gallery.image_path}
                                 alt="baby fabric shoes"
                                 width="75"
                                 height="75"
                                 className="showcase-img"/>
                        </a>

                        <div className="showcase-content">

                            <a href="#">
                                <h4 className="showcase-title">{res.name}</h4>
                            </a>

                            <div className="showcase-rating">
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                            </div>

                            <div className="price-box">
                                <del>$5.00</del>
                                <p className="price">$4.00</p>
                            </div>

                        </div>

                    </div>

                        )
                        )
                    }
                </div>

            </div>

        </div>
    );
}

export default NewProduct;