import './assets/css/style-prefix.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage, {Loader as HomePageLoader} from "./page/HomePage";
import AboutPage from "./page/AboutPage";
import ErrorPage from "./page/Error";
import Routerlayout, {Loader as MainRouteLoader} from "./page/Routerlayout";
import Contact, {Action as ContactAction} from "./page/contact";
import HomePageLayout, {Loader as homeLayoutLoader} from "./page/home/HomePageLayout";

function App() {

    // v2
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Routerlayout/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    index: '/',
                    element: <HomePageLayout />,
                    loader:homeLayoutLoader,
                    children:[
                        {
                            index: true,
                            element: <HomePage/>,
                            loader:HomePageLoader,
                        }
                    ]

                },
               {
                    path: '/contact',
                    element: <Contact/>,
                    action:ContactAction,
                }

            ],
            loader:MainRouteLoader,
        }
    ])


    return <RouterProvider router={router}/>
}

export default App;
