import React, {Suspense} from 'react';
import Slider from "../component/slider/slider";
import ProductFeacture from "../component/ProductFeacture";
import Recipes from "../component/recipes";
import axios from "axios";
import {Await, defer, useLoaderData} from "react-router-dom";
import SpecialProductFeacture from "../component/product/SpecialProduct";
import AuctionEndTime from "../component/product/auctionEndTime";

function HomePage(props) {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    const {topProduct,ProductAll,trendProduct,
        bestProduct,newProduct,auction_end_time}=useLoaderData()

    return (
        <>




                    <div className="product-box">


                        <div className="product-minimal">

                            <Suspense fallback={<p>loading...</p>}>
                                <Await resolve={trendProduct}>
                                    {(value) => <SpecialProductFeacture
                                        data={value} title={"Trending Product"}/>}
                                </Await>
                            </Suspense>

                            <Suspense fallback={<p>loading...</p>}>
                                <Await resolve={bestProduct}>
                                    {(value) => <SpecialProductFeacture
                                        data={value} title={"best sellers"}/>}
                                </Await>
                            </Suspense>

                            <Suspense fallback={<p>loading...</p>}>
                                <Await resolve={topProduct}>
                                    {(value) => <SpecialProductFeacture data={value} title={"Product Top"}/>}
                                </Await>
                            </Suspense>

                        </div>

                        <Suspense fallback={<p>loading...</p>}>
                            <Await resolve={auction_end_time}>
                                {(value) => <AuctionEndTime data={value} title={"Product All"}/>}
                            </Await>
                        </Suspense>


                        <Suspense fallback={<p>loading...</p>}>
                            <Await resolve={ProductAll}>
                                {(value) => <ProductFeacture data={value} title={"Product All"}/>}
                            </Await>
                        </Suspense>


                    </div>





        </>
    );
}

export default HomePage;

async function ProductRequestApi(where) {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    const response = await axios(siteUrl+'api/products/'+where, {
        headers: {
            'X-Api-Key': 'X-API-BigMard-Armenia',
            'X-API-Password': 'PSW-X-API-Big-Mard-Armenia',
        }
    })

    if(where!=='auction_end_time'){
        let arr = response.data;
        let newArr = [];

        for (let i = 0; i < arr.length; i += 4) {
            newArr.push(arr.slice(i, i + 4));
        }

        return newArr

    }
    return response.data
}

async function ProductAllApi() {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    const response = await axios(siteUrl+'api/products', {
        headers: {
            'X-Api-Key': 'X-API-BigMard-Armenia',
            'X-API-Password': 'PSW-X-API-Big-Mard-Armenia',
        }
    })
    console.log(response.data)
    return response.data

}



export  function  Loader({params}) {
    return defer({
        topProduct: ProductRequestApi('top'),
        ProductAll: ProductAllApi(),
        trendProduct: ProductRequestApi('trend'),
        bestProduct: ProductRequestApi('best'),
        auction_end_time: ProductRequestApi('auction_end_time'),
    })
}
