import React from 'react';

function Slider({data=[]}) {
    console.log(    data)
    return (
        <div className="banner">

            <div className="container">

                <div className="slider-container has-scrollbar">
                    {
                        data.map(res=>(
                    <div key={res.id} className="slider-item">

                        <img src={"http://loc.big.am/"+res.image} alt="women's latest fashion sale"
                             className="banner-img"/>

                        <div className="banner-content">

                            <p className="banner-subtitle">{res.title}</p>

                            <h2 className="banner-title">Women's latest fashion sale</h2>

                            <p className="banner-text">
                                starting at &dollar; <b>20</b>.00
                            </p>

                            <a href={res.url} className="banner-btn">Shop now</a>

                        </div>

                    </div>
                            ))}


                </div>

            </div>

        </div>
    );
}

export default Slider;