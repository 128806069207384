import React, {Suspense} from 'react';
import {Await, defer, Outlet, useLoaderData} from "react-router-dom";
import axios from "axios";
import Slider from "../../component/slider/slider";

function HomePageLayout(props) {
    const {slider}=useLoaderData()

    return (
        <div>

            <Suspense fallback={<p>loading...</p>}>
                <Await resolve={slider}>
                    {(value) => <Slider
                        data={value} title={"Trending Product"}/>}
                </Await>
            </Suspense>
            <Outlet />
        </div>
    );
}

export default HomePageLayout;
export function Loader({params}) {
    return defer({
        slider: slidersApi(),
    })


}
async function slidersApi() {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    const response = await axios(siteUrl+'api/sliders', {
        headers: {
            'X-Api-Key': 'X-API-BigMard-Armenia',
            'X-API-Password': 'PSW-X-API-Big-Mard-Armenia',
        }
    })
    console.log(response.data)
    return response.data

}
