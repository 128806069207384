import React from 'react';

function ProductFeacture({title="product",data}) {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    return (
        <>
            <div className="product-main">

                <h2 className="title">{title}</h2>

                <div className="product-grid">
                    {
                        data && data?.map((res) => (
                    <div className="showcase" key={res.id}>

                        <div className="showcase-banner">

                            <img src={siteUrl+res.item_gallery.image_path}
                                 alt="Mens Winter Leathers Jackets" width="300"
                                 className="product-img default"/>
                            <img src={siteUrl + res.item_gallery.image_path}
                                 alt="Mens Winter Leathers Jackets" width="300"
                                 className="product-img hover"/>

                            <p className="showcase-badge">15%</p>

                            <div className="showcase-actions">

                                <button className="btn-action">
                                    <ion-icon name="heart-outline"></ion-icon>
                                </button>

                                <button className="btn-action">
                                    <ion-icon name="eye-outline"></ion-icon>
                                </button>

                                <button className="btn-action">
                                    <ion-icon name="repeat-outline"></ion-icon>
                                </button>

                                <button className="btn-action">
                                    <ion-icon name="bag-add-outline"></ion-icon>
                                </button>

                            </div>

                        </div>

                        <div className="showcase-content">

                            <a href="#" className="showcase-category">{res.title}</a>

                            <a href="#">
                                <h3 className="showcase-title">Mens Winter Leathers Jackets</h3>
                            </a>

                            <div className="showcase-rating">
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star"></ion-icon>
                                <ion-icon name="star-outline"></ion-icon>
                                <ion-icon name="star-outline"></ion-icon>
                            </div>

                            <div className="price-box">
                                <p className="price">$48.00</p>
                                <del>$75.00</del>
                            </div>

                        </div>

                    </div>

                        ))
                    }

                </div>

            </div>


        </>
    );
}

export default ProductFeacture;