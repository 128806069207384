import React from 'react';
import {Link, useRouteError} from "react-router-dom";

function ErrorPage(props) {
    const error=useRouteError();

    let title="An Error this page";
    let message="Something went wrong"
    let status="403"
    console.log(error)
   if(error.status>=400 && error.status<500){
       title="Not found"
       status=error.status
       message=error.data
   }else  if(error.status>=500){
       title=" Internal Server Error"
       status=error.status
       message=error.data
   }


    return (
        <div className={'errorPage'}>

                <h2>{title}</h2>
                <span>{status}</span>
                <p>{message}</p>
            <Link to={"/"} children={"Go home"}/>
            <a href={`https://www.google.com/search?q=${message}`} target={"_blank"}>message</a>
        </div>
    );
}

export default ErrorPage;