import React from 'react';

function Loading({color}) {
    return (
        <div className={"loading"}>
            <h2 style={{color:color?color:"black"}}> Loading...</h2>
        </div>
    );
}

export default Loading;