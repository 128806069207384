import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function Categories({title = "Category", data}) {
    console.log(data)
    return (
        <div className="sidebar-category">

            <div className="sidebar-top">
                <h2 className="sidebar-title">{title}</h2>

                <button className="sidebar-close-btn" data-mobile-menu-close-btn>
                    <ion-icon name="close-outline"></ion-icon>
                </button>
            </div>

            <ul className="sidebar-menu-category-list">
                {
                    data && data?.map((res) => (
                        <li className="sidebar-menu-category" key={res.id}>
                            <button className="sidebar-accordion-menu" data-accordion-btn>

                                <div className="menu-title-flex mymenuClass">

                                    <ExitToAppIcon  />

                                    <a className="menu-title">{res.name}</a>
                                </div>

                            </button>
                        </li>
                    )
                    )
                }

            </ul>

        </div>
    );
}

export default Categories;