import React, {useEffect, useRef} from 'react';
import {Form, useActionData} from "react-router-dom";
import axios from "axios";

function Contact(props) {
    const data=useActionData()
    const formRef=useRef(null)
    useEffect(()=>{
       if(data){
           formRef.current.reset()
       }
    },[data])

    return (
        <div className="contact">
            <h2>Contact us</h2>
            <div className="contactUs">
                <div className="contactInformate">
                    <ul>
                        <li>
                            <span>Phone</span>
                            <p>098657545</p>
                        </li>
                        <li>
                            <span>web</span>
                            <p>www.ll.am</p>
                        </li>
                        <li>
                            <span>web</span>
                            <p>www.ll.am</p>
                        </li>
                    </ul>
                </div>
                <div className="contactMessage">
                    <Form method="post"  ref={formRef}>
                        <div className="d-flex">
                            <div>
                                <label htmlFor="">FullName</label>
                                <input type="text" name={"fullName"}/>
                            </div>
                            <div>
                                <label htmlFor="">Email</label>
                                <input type="text" name={"email"}/>
                            </div>
                        </div>
                            <div className="d-flex">
                                <div>
                                    <label htmlFor="">Phone</label>
                                    <input type="text" name={"phone"}/>
                                </div>
                                <div>
                                    <label htmlFor="">Subject</label>
                                    <input type="text" name={"subject"}/>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="">Message</label>
                                <textarea name="message"></textarea>
                            </div>
                        <button>Send Request</button>
                    </Form>

                </div>
            </div>
        </div>
    );
}

export default Contact;


export async function Action({request}){
    const data=await request.formData();
    const fullName=data.get("fullName")
    const email=data.get("email")
    const phone=data.get("phone")
    const subject=data.get("subject")
    const message=data.get("message")
        // ...validate

    const DataValue={
        fullName,email,phone,subject,message
    }

    const response=await axios.post(
        'https://mybase-8979f.firebaseio.com/contact.json',
        DataValue
        )

    return true


}