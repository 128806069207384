import React from 'react';

function SpecialProductFeacture({title = "product", data}) {
    const siteUrl = process.env.REACT_APP_SITE_URL;
    console.log(data)
    return (
        <>
            <div className="product-showcase">

                <h2 className="title">{title}</h2>

                <div className="showcase-wrapper  has-scrollbar">


                    {
                        data.length && data?.map((res,i) => (
                                <div className="showcase-container" key={i}>
                                    {res.length&&res?.map((val) => (
                                        <div className="showcase" key={val.id}>

                                            <a href="#" className="showcase-img-box">
                                                <img src={siteUrl + val.item_gallery.image_path}
                                                     alt="pocket watch leather pouch" className="showcase-img"
                                                     width="70"/>
                                            </a>

                                            <div className="showcase-content">

                                                <a href="#">
                                                    <h4 className="showcase-title">{val.title}</h4>
                                                </a>

                                                <a href="#" className="showcase-category">{val.name}</a>

                                                <div className="price-box">
                                                    <p className="price">$50.00</p>
                                                    <del>$34.00</del>
                                                </div>

                                            </div>

                                        </div>
                                    ))

                                    }

                                </div>
                            )
                        )
                    }


                </div>

            </div>


        </>
    );
}

export default SpecialProductFeacture;